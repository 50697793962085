.account-menu::-webkit-scrollbar-track {
    background: #ffffff00;
    border-radius: 100px;
    margin-block: 13px;
}

/* Handle */
.account-menu::-webkit-scrollbar-thumb {
    background: #73737330;
    border-radius: 100px;
    visibility: hidden;
}

.account-menu:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}