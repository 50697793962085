.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #F7F8FC;
}

.pac-container {
  z-index: 1600 !important;
}

.grid_container {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto auto 1fr;
  height: 100%;
  position: relative;
}

.sidebar_main {
  grid-column: 1/1;
  grid-row: 1/3;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.appbar_container {
  grid-column: 2/5;
  grid-row: 1/1;
  display: flex;
  flex-direction: column;
}

.sidebar_container {
  grid-column: 2/3;
  grid-row: 2/3;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.routes_container {
  grid-column: 3/4;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.right_container {
  grid-column: 4/4;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}