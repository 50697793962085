.scrollbar-content::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}

.scrollbar-content-small::-webkit-scrollbar {
    width: 11px;
    height: 5px;
}

/* Track */
.scrollbar-content::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 1px;
}

/* Handle */
.scrollbar-content::-webkit-scrollbar-thumb {
    background: #7373734a;
    border-radius: 1px;
}

/* Handle on hover */
.scrollbar-content::-webkit-scrollbar-thumb:hover {
    background: #73737370;
}

.custom-zoom [data-rmiz-modal-overlay="hidden"] {
    background-color: rgb(56, 58, 89, 0);
}

.custom-zoom [data-rmiz-modal-overlay="visible"] {
    background-color: rgba(0, 0, 0, 0.44);
}

.toolbar-expenses::-webkit-scrollbar {
    width: 11px;
    height: 6px;
}

.small-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.fancybox-overlay {
    z-index: 10000000000 !important;
}

/* .MuiSvgIcon-root {
    pointer-events: none;
} */

.hover-scrollbar {
    overflow-y: auto;
}

.hover-scrollbar::-webkit-scrollbar-thumb {
    visibility: hidden;
}

.hover-scrollbar:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

.rich-text-editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    padding-block: 10px;
    padding-inline: 15px;
    padding-top: 0;
    box-sizing: border-box;
    border: 1px solid #eaeaea;
}

.rich-text-editor-content {
    flex: 1;
    flex-basis: 0;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
    box-sizing: border-box;

}

/* scanner button style */
/* From Uiverse.io by adeladel522 */
.scanner-button {
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.scanner-button:hover::before {
    animation: shine 1.5s ease-out infinite;
}

.scanner-button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}