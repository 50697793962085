body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  display: none;
  height: 0;
  overflow: hidden;
}

.no-scrollbar::-webkit-scrollbar {
  height: 0;
}

.fullWidth {
  width: 100%;
}

.typing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.typing span {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: #979797;
  animation: typing 1s infinite;
}

@keyframes typing {
  0% {
    opacity: 0.2;
    transform: scale(1);
  }

  33% {
    opacity: 1;
    transform: scale(1.3);
  }

  66% {
    opacity: 0.2;
    transform: scale(1);
  }

  100% {
    opacity: 0.2;
    transform: scale(1);
  }
}

/* Track */
.data-grid-light ::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.data-grid-light ::-webkit-scrollbar-thumb {
  background: #dad9d9;
  border: 1px solid #d1cfcf;
}

/* Handle on hover */
.data-grid-light ::-webkit-scrollbar-thumb:hover {
  background: #c8c7c7;
}

/* Track */
.data-grid-dark ::-webkit-scrollbar-track {
  background: #202124;
}

/* Handle */
.data-grid-dark ::-webkit-scrollbar-thumb {
  background: #505050;
}

/* Handle on hover */
.data-grid-dark ::-webkit-scrollbar-thumb:hover {
  background: #73737350;
}

/* PDF */
.pdf-document {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pdf-page {
  border-radius: 3px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
}